let config = {
    s3: {
        REGION: "us-west-2",
        BUCKET: "forwood-newmont-prod-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-newmont-prod-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "us-west-2",
        URL: "https://qkfsm7a7bzgxbjajtgmv4mf3pa.appsync-api.us-west-2.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "us-west-2",
        URL: "https://i9rv94hh2c.execute-api.us-west-2.amazonaws.com/env",
        USER_TOKEN_URL: "https://8f1caoi0e8.execute-api.us-west-2.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.newmont.forwoodsafety.com",
        WEBSOCKET: "wss://60brha8aij.execute-api.us-west-2.amazonaws.com/prod"
    },
    cognito: {
        REGION: "us-west-2",
        USER_POOL_ID: "us-west-2_wGCB0IPfa",
        APP_CLIENT_ID: "16h68ts2jqsaqt1d30op1jum83",
        IDENTITY_POOL_ID: "us-west-2:0c6ee556-b098-4201-8d13-9c1bbd4595f6",
        USERPOOL_HOSTED_DOMAIN: "forwood-newmont-id-prod",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.newmont.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.newmont.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::656307490452:role/prod-CA",
        get USERPOOL_ADFS_LAUNCH_URL () {
          return 'https://'+this.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+this.APP_CLIENT_ID;
        }
    },
    samlProvider: {
      NAME: 'Newmont',
      SUPPORTED_LIST: 'Newmont,ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.34.5",
        HOSTNAME: "id.newmont.forwoodsafety.com",
        COOKIE_DOMAIN: ".newmont.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "9d9b6658-6cf0-4a2c-b388-be57dfc1bcc3",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.newmont.forwoodsafety.com"
    }
};

export default config;
